import axios from 'axios';
import copy from 'copy-text-to-clipboard';
import { GoogleCalendar, ICalendar } from 'datebook';
import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import QRCode from 'qrcode';
import '@typeform/embed/build/css/popup.css';
import { __, trim } from '@/support.js';
import { Alpine } from '../../vendor/livewire/livewire/dist/livewire.esm';
import { bounds } from './mapping.js';

import.meta.glob(['./alpine/*'], { eager: true });

Alpine.data('ImportPastEventsModal', () => ({
    open: false,
    events: [],
    init() {
        this.$watch('open', (value) =>
            value
                ? axios.get(route('api:events.past.index')).then(({ data: { events } }) => (this.events = events))
                : setTimeout(() => (this.events = []), 500),
        );
    },
    __,
}));

Alpine.data('MultiEventMap', (events, showHubLinksInPopups = true) => ({
    map: null,
    init() {
        if (this.showMap === true || this.showMap === undefined) {
            this.$nextTick(() => this.initialize());
        }
        if (this.showMap !== undefined) {
            this.$watch('showMap', (value) => (value ? this.initialize() : (this.map = null)));
        }
    },
    initialize() {
        Array.from(this.$el.children).map((c) => c.remove());
        this.map = new mapboxgl.Map({
            accessToken: CultureDays.mapboxToken,
            container: this.$el,
            style: 'mapbox://styles/mapbox/streets-v11?optimize=true',
            bounds: bounds(),
        });
        if (!events?.length) return;
        this.map.on('load', () => {
            axios
                .get(route('api:events.geojson'), { params: { events } })
                .then(({ data }) => {
                    // TODO do this asynchronously and/or in `init()`
                    this.map.addSource('events', {
                        type: 'geojson',
                        cluster: true,
                        clusterRadius: 40,
                        data,
                    });

                    if (data.features.length) {
                        let lons = data.features.map((feat) => Number(feat.geometry.coordinates[0]));
                        let lats = data.features.map((feat) => Number(feat.geometry.coordinates[1]));
                        this.map.fitBounds(
                            [
                                [Math.min(...lons), Math.min(...lats)],
                                [Math.max(...lons), Math.max(...lats)],
                            ],
                            { padding: 40, maxZoom: 13, speed: 3 },
                        );
                    }

                    this.map.addLayer({
                        id: 'single_activities',
                        type: 'circle',
                        source: 'events',
                        filter: ['!', ['has', 'point_count']],
                        paint: {
                            'circle-color': '#ff8142',
                            'circle-radius': 6,
                            'circle-stroke-width': 2,
                            'circle-stroke-color': '#d15b29',
                        },
                    });
                    this.map.on('mouseenter', 'single_activities', () => (this.map.getCanvas().style.cursor = 'pointer'));
                    this.map.on('mouseleave', 'single_activities', () => (this.map.getCanvas().style.cursor = ''));

                    this.map.addLayer({
                        id: 'clustered_activities',
                        type: 'circle',
                        source: 'events',
                        filter: ['has', 'point_count'],
                        paint: {
                            'circle-color': '#ff8142',
                            'circle-radius': ['step', ['get', 'point_count'], 14, 10, 24, 25, 36, 50, 48],
                            'circle-stroke-color': '#d15b29',
                            'circle-stroke-width': 2,
                        },
                    });
                    this.map.addLayer({
                        id: 'clustered_activities_label',
                        type: 'symbol',
                        source: 'events',
                        filter: ['has', 'point_count'],
                        layout: {
                            'text-field': '{point_count}',
                            'text-size': ['step', ['get', 'point_count'], 12, 10, 14],
                        },
                    });
                    this.map.on('mouseenter', 'clustered_activities', () => (this.map.getCanvas().style.cursor = 'pointer'));
                    this.map.on('mouseleave', 'clustered_activities', () => (this.map.getCanvas().style.cursor = ''));

                    this.map.on('click', 'single_activities', (e) => {
                        let coords = e.features[0].geometry.coordinates.slice();
                        let event = e.features[0].properties;

                        while (Math.abs(e.lngLat.lng - coords[0]) > 180) {
                            coords[0] += e.lngLat.lng > coords[0] ? 360 : -360;
                        }

                        new mapboxgl.Popup()
                            .setLngLat(coords)
                            .setHTML(
                                `
<div>
    <h4 class="mb-1">
        <a class="font-sans text-base font-medium link" href="${route('events.show', event.uuid)}" target="_blank">
            ${__(JSON.parse(event.name))}
        </a>
    </h4>
    <p class="flex items-center font-sans text-sm leading-tighter text-grey-700">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="icon w-3 h-3 shrink-0"><path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path><circle cx="9" cy="7" r="4"></circle><path d="M23 21v-2a4 4 0 0 0-3-3.87"></path><path d="M16 3.13a4 4 0 0 1 0 7.75"></path></svg>
        <span class="ml-2">${trim(__(JSON.parse(event.organizer_name)), 120)}</span>
    </p>
    ${
        showHubLinksInPopups && event.hub
            ? `
    <p class="font-sans font-medium text-sm leading-tight mt-1">
        <a class="link text-brand-pink" href="${route('hubs.show', JSON.parse(event.hub).uuid)}">
            Hub: ${trim(__({ en: JSON.parse(event.hub).name_en, fr: JSON.parse(event.hub).name_fr }), 50)}
        </a>
    </p>
    `
            : ''
    }
</div>`,
                            )
                            .addTo(this.map);
                    });
                })
                .catch((e) => {
                    console.error('Failed to fetch GeoJSON.');
                    console.error(e);
                    throw e;
                });
        });

        this.map.on('click', 'clustered_activities', (e) => {
            let features = this.map.queryRenderedFeatures(e.point, { layers: ['clustered_activities'] });
            let { properties, geometry } = features[0];

            this.map.getSource('events').getClusterExpansionZoom(properties.cluster_id, (err, zoom) => {
                if (zoom < 17) {
                    this.map.easeTo({ center: geometry.coordinates, zoom });
                } else {
                    if (this.map.getZoom() < 15) {
                        this.map.easeTo({ center: geometry.coordinates, zoom: 15 });
                    }

                    this.map.getSource('events').getClusterLeaves(properties.cluster_id, 100, 0, (err, clusterFeatures) => {
                        let htmls = clusterFeatures.map(
                            ({ properties }) =>
                                `
<div>
    <h4 class="mb-1">
        <a class="font-sans text-base font-medium link" href="${route('events.show', properties.uuid)}" target="_blank">
            ${__(properties.name)}
        </a>
    </h4>
    <p class="flex items-center font-sans text-sm leading-tighter text-grey-700">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="icon w-3 h-3 shrink-0"><path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path><circle cx="9" cy="7" r="4"></circle><path d="M23 21v-2a4 4 0 0 0-3-3.87"></path><path d="M16 3.13a4 4 0 0 1 0 7.75"></path></svg>
        <span class="ml-2">${trim(__(properties.organizer_name), 120)}</span>
    </p>
    ${
        showHubLinksInPopups && event.hub
            ? `
    <p class="font-sans font-medium text-sm leading-tight mt-px">
        <a class="link text-brand-pink" href="${route('hubs.show', event.hub.uuid)}">
            Hub: ${trim(__({ en: event.hub.name_en, fr: event.hub.name_fr }), 50)}
        </a>
    </p>
    `
            : ''
    }
</div>
                                `,
                        );

                        new mapboxgl.Popup()
                            .setLngLat(geometry.coordinates)
                            .setHTML(`<div class="max-h-56 overflow-scroll space-y-2">${htmls.join('')}</div>`)
                            .addTo(this.map);
                    });
                }
            });
        });

        this.map.addControl(new mapboxgl.NavigationControl({ showCompass: false }), 'top-left');
    },
}));

Alpine.data('CollectionModal', (event) => ({
    open: false,
    loading: true,
    collections: [],
    init() {
        this.$watch('open', () => this.load());
    },
    async load() {
        this.collections = (await axios.get(route('api:events.collections.index', event))).data;
        this.loading = false;
    },
    async toggle(collection) {
        await axios.put(route('api:collectionEvents.update', [collection, event]));
        this.load();
    },
    __,
}));

Alpine.data('Share', () => ({
    open: false,
    toggle() {
        if (this.open) {
            return this.close();
        }
        this.$refs.button.focus();
        this.open = true;
    },
    close(focusAfter) {
        if (!this.open) return;
        this.open = false;
        focusAfter && focusAfter.focus();
    },
    facebook(app, url) {
        window.open(`https://www.facebook.com/dialog/share?app_id=${app}&href=${url}&display=popup`, '', 'height=600,width=500');
    },
    twitter(url) {
        window.open(`https://twitter.com/intent/tweet?url=${url}&text=&hashtags=CultureDays`, '', 'height=600,width=500');
    },
    copy(url) {
        copy(url);
    },
    async qr() {
        return await QRCode.toDataURL(location.href.split(/[?#]/)[0]);
    },
    ical(title, location, start, end) {
        return new ICalendar({ title, location, start: new Date(start), end: new Date(end) });
    },
    gcal(title, location, start, end) {
        return new GoogleCalendar({ title, location, start: new Date(start), end: new Date(end) }).render();
    },
}));

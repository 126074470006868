import axios from 'axios';
import { __ } from '@/support.js';
import { Alpine } from '../../../vendor/livewire/livewire/dist/livewire.esm';

Alpine.data('SocialImagePreviewer', (event, timeslots) => ({
    layout: 'bottom-panel',
    file: '0',
    timeslot: timeslots > 0 ? '0' : '',
    colour: 'black',
    language: 'en',
    downloading: false,
    async download() {
        this.downloading = true;
        try {
            let response = await axios.post(route('api:events.download-social-image', event), {
                layout: this.layout,
                file: this.file,
                timeslot: this.timeslot,
                colour: this.colour,
                language: this.language,
            });
            let anchor = document.createElement('a');
            anchor.href = `data:image/png;base64,${response.data}`;
            anchor.download = 'image.png';
            document.body.appendChild(anchor);
            anchor.click();
            document.body.removeChild(anchor);
        } finally {
            this.downloading = false;
        }
    },
    __,
}));

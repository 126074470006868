import { Alpine } from '../../../vendor/livewire/livewire/dist/livewire.esm';

Alpine.data('OnThisPage', () => ({
    headings: undefined,
    visibleHeadingId: null,
    expanded: false,
    init() {
        this.headings = document.querySelectorAll(['h2', 'h3', 'h4'].map((h) => `[on-this-page] > ${h}:not([no-sidebar])`).join(', '));
        this.onScroll();
    },
    onScroll() {
        // Highlight the first heading above an imaginary line drawn horizontally across the screen
        const relativeTop = window.innerHeight / 3;
        const headingsByDistanceFromTop = {};
        // Populate an object of headings using their distance from our imaginary line as the keys
        this.headings.forEach((heading) => {
            headingsByDistanceFromTop[heading.getBoundingClientRect().top - relativeTop] = heading;
        });
        // Grab the first one above the line
        const closestNegativeTop = Math.max(...Object.keys(headingsByDistanceFromTop).filter((top) => top < 0));
        // If there wasn't one don't highlight anything
        if (closestNegativeTop >= 0 || [Infinity, NaN, -Infinity].includes(closestNegativeTop)) {
            return (this.visibleHeadingId = null);
        }
        // Highlight the heading
        this.visibleHeadingId = headingsByDistanceFromTop[closestNegativeTop].id;
    },
}));

import axios from 'axios';
import { Alpine } from '../../../vendor/livewire/livewire/dist/livewire.esm';

Alpine.data('AttachedHub', (event, hub) => ({
    async leave() {
        if (confirm('Are you sure you want to remove your event from this hub?')) {
            try {
                await axios.delete(route('api:eventHubs.destroy', [event, hub]));
            } finally {
                location.reload();
            }
        }
    },
}));

import axios from 'axios';
import { __ } from '@/support';
import { Alpine } from '../../../vendor/livewire/livewire/dist/livewire.esm';

Alpine.store('bookmarks', {
    key: 'culturedays.bookmarks.events',
    events: new Set(),
    data: {},
    init() {
        this.load();
    },
    load() {
        try {
            this.events = new Set(JSON.parse(localStorage.getItem(this.key)));
        } catch (e) {
            this.events = new Set();
            throw e;
        } finally {
            this.flush();
            if (this.events.size) {
                this.fetch();
            }
        }
    },
    addEvent(uuid) {
        this.events.add(uuid);
        this.flush();
        this.fetch();
    },
    removeEvent(uuid) {
        this.events.delete(uuid);
        this.flush();
        if (this.events.size) {
            this.fetch();
        } else {
            this.data = {};
        }
    },
    has(uuid) {
        return this.events.has(uuid);
    },
    fetch() {
        axios.post(route('api:bookmarks.show'), { events: Array.from(this.events) }).then(({ data }) => {
            this.events = new Set(Object.keys(data));
            this.data = data;
            this.flush();
        });
    },
    flush() {
        localStorage.setItem(this.key, JSON.stringify(Array.from(this.events)));
    },
});

Alpine.data('Bookmarks', (demo = []) => ({
    open: false,
    saving: false,
    success: false,
    errors: {},
    email: '',
    init() {
        if (demo.length) {
            Alpine.store('bookmarks').key += '-demo';
            Alpine.store('bookmarks').events = new Set(demo);
            Alpine.store('bookmarks').fetch();
        }
    },
    remove(uuid) {
        Alpine.store('bookmarks').removeEvent(uuid);
        if (Alpine.store('bookmarks').events.size === 0) {
            this.open = false;
        }
    },
    save() {
        axios
            .post(route('api:bookmarks.save'), {
                email: this.email,
                events: Array.from(Alpine.store('bookmarks').events),
            })
            .then(() => {
                this.success = true;
                this.saving = false;
                this.email = '';
                setTimeout(() => (this.success = false), 3000);
            })
            .catch((e) => {
                if (e.response.status === 422) {
                    this.errors = e.response.data.errors;
                } else {
                    throw e;
                }
            });
    },
    __,
}));

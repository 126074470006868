import LazyLoad from 'vanilla-lazyload';
import anchor from '@alpinejs/anchor';
import collapse from '@alpinejs/collapse';
import focus from '@alpinejs/focus';
import intersect from '@alpinejs/intersect';
import * as Sentry from '@sentry/browser';
import '@/dom.js';
import { Alpine, Livewire } from '../../vendor/livewire/livewire/dist/livewire.esm';

Alpine.plugin(anchor);
Alpine.plugin(collapse);
Alpine.plugin(focus);
Alpine.plugin(intersect);

if (import.meta.env.PROD) {
    Sentry.init({
        release: __RELEASE__,
        environment: import.meta.env.VITE_APP_ENV,
        dsn: import.meta.env.VITE_SENTRY_DSN,
        denyUrls: [/graph\.facebook\.com/i, /connect\.facebook\.net\/en_US\/all\.js/i, /extensions\//i, /^chrome:\/\//i],
        ignoreErrors: ['MoatMAK', 'Failed to initialize WebGL', 'mraid', 'ResizeObserver loop limit exceeded'],
        integrations: [
            Sentry.browserTracingIntegration(),
            Sentry.httpClientIntegration({
                failedRequestStatusCodes: [419, [500, 599]],
            }),
        ],
        tracesSampleRate: 1.0,
        tracePropagationTargets: [
            'localhost',
            /^https:\/\/culturedays\.test/,
            /^https:\/\/culturedays\.ca/,
            /^https:\/\/archive\.culturedays\.ca/,
            /^https:\/\/embed\.culturedays\.ca/,
        ],
    });

    if (CultureDays.user) {
        Sentry.setUser({
            id: CultureDays.user.id,
            email: CultureDays.user.email,
        });
    }
}

window.ll = new LazyLoad({ elements_selector: '.lazy', threshold: 600 });

Livewire.start();

import axios from 'axios';
import { sum } from 'lodash';
import { __ } from '@/support';
import { Alpine } from '../../../vendor/livewire/livewire/dist/livewire.esm';

Alpine.data('RsvpAttendeeList', (initial) => ({
    open: false,
    cancelled: [],
    count: 0,
    deleting: null,
    init() {
        this.count = sum(Object.values(initial));
    },
    async promptDelete(id) {
        if (
            confirm(
                __(
                    'Are you sure you want to remove this attendee? They will be notified by email that their RSVP has been cancelled. Depending on the capacity and registration deadline of this timeslot, they may not be able to RSVP again.',
                ),
            )
        ) {
            try {
                this.deleting = id;
                await axios.delete(route('api:rsvps.destroy', id));
                this.cancelled.push(id);
                this.count = this.count - initial[id];
            } finally {
                this.deleting = null;
            }
        }
    },
}));

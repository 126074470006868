import { arrow, autoUpdate, computePosition, flip, offset } from '@floating-ui/dom';
import { Alpine } from '../../../vendor/livewire/livewire/dist/livewire.esm';

Alpine.directive('tooltip', (el, { expression }) => {
    let tooltip = document.createElement('div');
    tooltip.innerHTML = expression;
    tooltip.className =
        'hidden absolute top-0 left-0 w-max-content bg-sky-200 text-sm px-2 py-1 leading-tight font-medium rounded text-sky-950';
    document.body.append(tooltip);

    let arrowEl = document.createElement('div');
    arrowEl.className = 'absolute size-3 bg-sky-200 rotate-45';
    tooltip.appendChild(arrowEl);

    autoUpdate(el, tooltip, () =>
        computePosition(el, tooltip, {
            placement: 'top',
            middleware: [offset(8), flip(), arrow({ element: arrowEl })],
        }).then(({ x, y, placement, middlewareData }) => {
            Object.assign(tooltip.style, { left: `${x}px`, top: `${y}px` });

            if (middlewareData.arrow) {
                let { x, y } = middlewareData.arrow;
                let side = { top: 'bottom', right: 'left', bottom: 'top', left: 'right' }[placement];
                Object.assign(arrowEl.style, {
                    left: x != null ? `${x}px` : '',
                    top: y != null ? `${y}px` : '',
                    right: '',
                    bottom: '',
                    [side]: '-5px',
                });
            }
        }),
    );

    let show = () => tooltip.classList.remove('hidden');
    let hide = () => tooltip.classList.add('hidden');

    [
        [['mouseenter', 'focus'], show],
        [['mouseleave', 'blur'], hide],
    ].map(([events, listener]) => events.map((e) => el.addEventListener(e, listener)));
});

import axios from 'axios';
import { Alpine } from '../../../vendor/livewire/livewire/dist/livewire.esm';

Alpine.data('ActivityReminder', (uuid) => ({
    email: '',
    honeypot: {
        name: '',
        value: '',
        valid_from: '',
    },
    succeeded: false,
    errors: {},
    async submit() {
        axios
            .post(route('api:events.reminders.store', uuid), {
                email: this.email,
                [this.honeypot.name]: this.honeypot.value,
                valid_from: this.honeypot.valid_from,
            })
            .then(() => {
                this.succeeded = true;
                setTimeout(() => this.$refs.dialog.close(), 5000);
            })
            .catch((e) => {
                if (e.response.status === 422) {
                    this.errors = e.response.data.errors;
                } else {
                    throw e;
                }
            });
    },
}));

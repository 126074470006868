import { GeolocateControl, Map, Marker, NavigationControl } from 'mapbox-gl';
import { Alpine } from '../../../vendor/livewire/livewire/dist/livewire.esm';

Alpine.data('SingleEventMap', (coordinates) => ({
    unwatch: undefined,
    init() {
        this.unwatch = Alpine.watch(
            () => this.showMap,
            () => this.initialize(),
        );
    },
    initialize() {
        let map = new Map({
            accessToken: CultureDays.mapboxToken,
            container: this.$el,
            style: 'mapbox://styles/mapbox/streets-v12?optimize=true',
            center: coordinates,
            zoom: 14,
        });
        new Marker().setLngLat(coordinates).addTo(map);
        map.addControl(new NavigationControl({ showCompass: false }), 'top-left');
        map.addControl(
            new GeolocateControl({
                positionOptions: {
                    enableHighAccuracy: true,
                    timeout: 10000,
                },
                showUserLocation: true,
                fitBoundsOptions: { maxZoom: 16 },
            }).on('geolocate', ({ coords: { longitude, latitude } }) => map.flyTo({ center: [longitude, latitude], zoom: 12 })),
            'top-left',
        );
        this.unwatch();
    },
}));

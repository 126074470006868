import axios from 'axios';
import { Alpine } from '../../../vendor/livewire/livewire/dist/livewire.esm';

Alpine.data('InvitingHub', (event, hub) => ({
    async accept() {
        try {
            await axios.patch(route('api:eventHubs.update', [event, hub]), { join: true });
        } finally {
            location.reload();
        }
    },
    async decline() {
        try {
            await axios.patch(route('api:eventHubs.update', [event, hub]), { join: false });
        } finally {
            location.reload();
        }
    },
}));

import { createPopup } from '@typeform/embed';
import { Alpine } from '../../../vendor/livewire/livewire/dist/livewire.esm';

Alpine.data('Typeform', (formId) => ({
    toggle: null,
    init() {
        const { toggle } = createPopup(formId, {
            hidden: {
                user_id: CultureDays.user?.id,
            },
        });
        this.toggle = toggle;
    },
    // https://alpinejs.dev/directives/bind#bind-directives
    trigger: {
        ['@click']() {
            this.toggle();
        },
    },
}));

import axios from 'axios';
import { Alpine } from '../../../vendor/livewire/livewire/dist/livewire.esm';

Alpine.data('ActivityRsvp', () => ({
    name: '',
    email: '',
    count: undefined,
    timeslot: '',
    honeypot: {
        name: '',
        value: '',
        valid_from: '',
    },
    succeeded: false,
    unverified: false,
    dupe: false,
    errors: {},
    async submit() {
        axios
            .post(route('api:rsvps.store', this.timeslot), {
                name: this.name,
                email: this.email,
                count: this.count,
                [this.honeypot.name]: this.honeypot.value,
                valid_from: this.honeypot.valid_from,
            })
            .then((response) => {
                this.succeeded = true;
                if (response.status === 202) {
                    this.unverified = true;
                }
                if (response.status === 204) {
                    this.dupe = true;
                }
            })
            .catch((e) => {
                if (e.response.status === 422) {
                    this.errors = e.response.data.errors;
                } else {
                    throw e;
                }
            });
    },
}));
